import { useEffect } from 'react'
import Seo from 'src/components/seo/Seo'

import storeConfig from '../../store.config'

function Page() {
  useEffect(() => {
    window.location.href = storeConfig.checkoutUrl
  }, [])

  return (
    <>
      <Seo />

      <div>loading...</div>
    </>
  )
}

export default Page
